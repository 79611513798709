import { render, staticRenderFns } from "./informationAdd.vue?vue&type=template&id=5f846f52&scoped=true"
import script from "./informationAdd.vue?vue&type=script&lang=ts"
export * from "./informationAdd.vue?vue&type=script&lang=ts"
import style0 from "./informationAdd.vue?vue&type=style&index=0&id=5f846f52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f846f52",
  null
  
)

export default component.exports